var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"eager":"","persistent":"","scrollable":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"CSEligFormref",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-card',[_c('v-card-title',{staticClass:"dialog-header pt-5 pb-5 pl-6",attrs:{"dark":""}},[(_vm.action == 'Add')?_c('span',[_vm._v(" Add Civil Service Eligibility")]):_vm._e(),(_vm.action == 'Update')?_c('span',[_vm._v("Update Civil Service Eligibility")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.closeD();
              _vm.dialog = false;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col'),_c('v-card-text',{staticStyle:{"max-height":"700px"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"rules":[_vm.formRules.required],"dense":"","outlined":"","required":"","label":"Career Service / RA 1080 (Board/Bar)...","color":"#6DB249"},model:{value:(_vm.csEligibilityModel.eligibility_type),callback:function ($$v) {_vm.$set(_vm.csEligibilityModel, "eligibility_type", $$v)},expression:"csEligibilityModel.eligibility_type"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"rules":[_vm.formRules.required],"dense":"","outlined":"","required":"","label":"Rating","color":"#6DB249"},model:{value:(_vm.csEligibilityModel.rating),callback:function ($$v) {_vm.$set(_vm.csEligibilityModel, "rating", $$v)},expression:"csEligibilityModel.rating"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-menu',{ref:"confermentDate",attrs:{"close-on-content-click":false,"return-value":_vm.csEligibilityModel.conferment_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.csEligibilityModel, "conferment_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.csEligibilityModel, "conferment_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","rules":[_vm.formRules.required],"chips":"","small-chips":"","label":"Date of Examination/Conferment (FROM)","color":"#6DB249","readonly":""},model:{value:(_vm.csEligibilityModel.conferment_date),callback:function ($$v) {_vm.$set(_vm.csEligibilityModel, "conferment_date", $$v)},expression:"csEligibilityModel.conferment_date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"color":"#6DB249","no-title":"","scrollable":""},model:{value:(_vm.csEligibilityModel.conferment_date),callback:function ($$v) {_vm.$set(_vm.csEligibilityModel, "conferment_date", $$v)},expression:"csEligibilityModel.conferment_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#6DB249"},on:{"click":function($event){return _vm.$refs.confermentDate.save(
                          _vm.csEligibilityModel.conferment_date
                        )}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"my-n3",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption red--text"},[_c('b',[_vm._v("Note:")]),_c('i',[_vm._v(" You may not fill/encode this field if the Date of Examination/Conferment (FROM) is the same as the Date of Examination/Conferment (TO). ")])])]),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-menu',{ref:"confermentDateTo",attrs:{"close-on-content-click":false,"return-value":_vm.csEligibilityModel.conferment_date_to,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.csEligibilityModel, "conferment_date_to", $event)},"update:return-value":function($event){return _vm.$set(_vm.csEligibilityModel, "conferment_date_to", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","chips":"","clearable":"","small-chips":"","label":"Date of Examination/Conferment (TO)","color":"#6DB249","readonly":""},model:{value:(_vm.csEligibilityModel.conferment_date_to),callback:function ($$v) {_vm.$set(_vm.csEligibilityModel, "conferment_date_to", $$v)},expression:"csEligibilityModel.conferment_date_to"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"color":"#6DB249","min":_vm.csEligibilityModel.conferment_date,"no-title":"","scrollable":""},model:{value:(_vm.csEligibilityModel.conferment_date_to),callback:function ($$v) {_vm.$set(_vm.csEligibilityModel, "conferment_date_to", $$v)},expression:"csEligibilityModel.conferment_date_to"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#6DB249"},on:{"click":function($event){return _vm.$refs.confermentDateTo.save(
                          _vm.csEligibilityModel.conferment_date_to
                        )}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"rules":[_vm.formRules.required],"dense":"","outlined":"","required":"","label":"Place of Examination/Conferment","color":"#6DB249"},model:{value:(_vm.csEligibilityModel.conferment_place),callback:function ($$v) {_vm.$set(_vm.csEligibilityModel, "conferment_place", $$v)},expression:"csEligibilityModel.conferment_place"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"rules":[_vm.formRules.required],"dense":"","outlined":"","required":"","label":"License Number","color":"#6DB249"},model:{value:(_vm.csEligibilityModel.license_num),callback:function ($$v) {_vm.$set(_vm.csEligibilityModel, "license_num", $$v)},expression:"csEligibilityModel.license_num"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"rounded-lg",attrs:{"dense":"","outlined":"","required":"","label":"License Validity Non-expiring","color":"#6DB249"},on:{"change":function($event){return _vm.NoValidity()}},model:{value:(_vm.no_validity),callback:function ($$v) {_vm.no_validity=$$v},expression:"no_validity"}})],1),(!_vm.no_validity)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-menu',{ref:"licenseValidity",attrs:{"close-on-content-click":false,"return-value":_vm.csEligibilityModel.validity,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.csEligibilityModel, "validity", $event)},"update:return-value":function($event){return _vm.$set(_vm.csEligibilityModel, "validity", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","rules":!_vm.no_validity ? [] : [_vm.formRules.required],"chips":"","small-chips":"","color":"#6DB249","label":"License Validity","readonly":""},model:{value:(_vm.csEligibilityModel.validity),callback:function ($$v) {_vm.$set(_vm.csEligibilityModel, "validity", $$v)},expression:"csEligibilityModel.validity"}},'v-text-field',attrs,false),on))]}}],null,false,2297317589)},[_c('v-date-picker',{attrs:{"color":"#6DB249","no-title":"","scrollable":""},model:{value:(_vm.csEligibilityModel.validity),callback:function ($$v) {_vm.$set(_vm.csEligibilityModel, "validity", $$v)},expression:"csEligibilityModel.validity"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#6DB249"},on:{"click":function($event){return _vm.$refs.licenseValidity.save(
                          _vm.csEligibilityModel.validity
                        )}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e()],1)],1)],1),_c('v-divider'),(_vm.action == 'Add')?_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.closeD()}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")]),_vm._v(" Cancel ")],1),_c('v-btn',{staticClass:"white--text",attrs:{"color":"#519043"},on:{"click":function($event){return _vm.addCSElig()}}},[_c('v-icon',[_vm._v("mdi-check-circle")]),_vm._v(" Save Details ")],1)],1):_vm._e(),(_vm.action == 'Update')?_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")]),_vm._v(" Cancel ")],1),_c('v-btn',{staticClass:"white--text",attrs:{"color":"#519043"},on:{"click":function($event){return _vm.updateCSElig()}}},[_c('v-icon',[_vm._v("mdi-check-circle")]),_vm._v(" Update Details ")],1)],1):_vm._e()],1)],1)],1),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }